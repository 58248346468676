<template>
  <div>
    <v-container >
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            1. Действия сотрудников при возникновении пожара
          </v-card-title>
        </v-col>
        <v-col cols="12">
          <v-card class="pa-5">
            <v-card-title class="font-weight-light">
              Каждый сотрудник при обнаружении очага загорания или признаков горения (задымление, запах гари, повышение температуры и т.п.) должен:
            </v-card-title>
            <v-row>
              <v-col cols="6">
                <v-card
                class="pa-2 d-flex justify-center align-center"
                full-width
                flat

                >
                  <v-icon color="red" size="100">mdi-numeric-1</v-icon>
                  <v-card-text class="title font-weight-light">
                    Оповестить пожарную службу по номеру 101 или 112
                  </v-card-text>
                </v-card>
                <v-card
                class="pa-2 d-flex justify-center align-center"
                full-width
                flat

                >
                  <v-icon color="red" size="100">mdi-numeric-2</v-icon>
                  <v-card-text class="title font-weight-light">
                    Оповестить о пожаре всех, кто находится поблизости и проинформировать руководителя
                  </v-card-text>
                </v-card>
                <v-card
                class="pa-2 d-flex justify-center align-center"
                full-width
                flat

                >
                  <v-icon color="red" size="100">mdi-numeric-3</v-icon>
                  <v-spacer></v-spacer>
                  <v-card-text class="title font-weight-light">
                    Принять меры по эвакуации людей. Противодействовать распространению паники
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card
                class="pa-2 d-flex justify-center align-center"
                full-width
                flat

                >
                  <v-icon color="red" size="100">mdi-numeric-4</v-icon>
                  <v-spacer></v-spacer>
                  <v-card-text class="title font-weight-light">
                    При угрозе здоровью и жизни покинуть здание. При задымлении передвигайтесь на четвереньках, используя влажную ткань для дыхания
                  </v-card-text>
                </v-card>
                <v-card
                class="pa-2 d-flex justify-center align-center"
                full-width
                flat

                >
                  <v-icon color="red" size="100">mdi-numeric-5</v-icon>
                  <v-spacer></v-spacer>
                  <v-card-text class="title font-weight-light">
                    Приступить к тушению огня первичными средствами пожаротушения
                  </v-card-text>
                </v-card>
                <v-card
                class="pa-2 d-flex justify-center align-center"
                full-width
                flat

                >
                  <v-card-text class="title font-weight-bold">
                    <span class="red--text ">Главное правило при пожаре:</span> быстро и без паники покинуть горящее здание, оповещая остальных и помогая отстающим
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-card-text class="title font-weight-light">
            <p>Вызвать пожарную охрану необходимо даже если пожар уже потушен своими силами.</p>
            <p>Огонь может остаться незамеченным в скрытых местах и разгореться еще сильнее.</p>
            <p>Поэтому место пожара должно быть осмотрено специалистами пожарной охраны, который вправе подтвердить полную ликвидацию пожара.</p>
          </v-card-text>
        </v-col>
        <v-col class="d-flex justify-center align-center" cols="4">
          <v-dialog
            v-model="dialog1"
            width="70%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="110"
                  hover

                  >
                <v-img
                  lazy-src="img/material/fire.svg"
                  max-width="80"
                  src="img/material/fire.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Правила передачи сообщения о пожаре
                </v-card-text>
              </v-card>
            </template>
            <v-card class="noselect">
              <v-row class="align-center">
                <v-col cols="8">
                <div class="pa-5">
                  <v-card-title class="headline">
                    При передаче сообщения о пожаре по телефону необходимо<br> соблюдать правила:
                  </v-card-title>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon size="50" color="red" class="">mdi-numeric-1</v-icon>
                    Сообщите, что передается сообщение о пожаре;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                   <v-icon size="50" color="red" class="">mdi-numeric-2</v-icon>
                    Назовите адрес: наименование улицы, номер дома;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon size="50" color="red" class="">mdi-numeric-3</v-icon>
                    Укажите (по возможности):
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="pl-10">mdi-check-all</v-icon>
                    место пожара;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="pl-10">mdi-check-all</v-icon>
                    внешние признаки;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="pl-10">mdi-check-all</v-icon>
                    наличие угрозы людям, удобный проезд.
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon size="50" color="red" class="">mdi-numeric-4</v-icon>
                      Сообщите свою фамилию и номер телефона.
                    </v-card-text>
                </div>
                </v-col>
                <v-col cols="4">
                  <v-img
                    lazy-src="img/material/101-call.svg"
                    max-width="500"
                    height="300"
                    src="img/material/101-call.svg"
                    class="ma-10 pa-5"
                    contain
                  ></v-img>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog1 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-col>
      </v-row>
      <Hint></Hint>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    dialog1: null
  }),
  components: {
    Hint
  }
}
</script>
